
import { Component, Vue } from "vue-property-decorator";
import { UserService, GaodeService, WechatService } from "src/services";
import { Toast } from "vant";
import { tools } from "src/utils";
import { DesSecretUtils } from "src/utils";
import privacy from "../privacy.vue";
import agreement from "../agreement.vue";
import globalConfig from "../../config/index";

const showRightsCode = globalConfig.showRightsCode;
Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate" // for vue-router 2.2+
]);

@Component({
    components:
    {
        agreement,
        privacy
    }
})
export default class register extends Vue
{

    /**
     * 显示协议弹框
     * @private
     * @returns boolean
     */
     private showOverlay: boolean = false;

    /**
     * 是否显示服务协议
     * @private
     * @returns boolean
     */
     private showAgreement: boolean = false;
    
    /**
     * 是否显示隐私协议
     * @private
     * @returns boolean
     */
    private showPrivacy: boolean = false;

    /**
     * 是否阅读协议
     * @private
     * @returns boolean
     */
    private isRead: boolean = false;

    /**
     * 手机号是否错误
     * @private
     * @returns boolean
     */
    private phoneErr: boolean = false;

    /**
     * 注册数据模型
     * @private
     * @returns any
     */
    private modelData: any =
    {
        smsType: 9,     // 客户端登录验证码
        phone: ""       // 手机号码
    };

    /**
     * 获取当前登入机构信息
     * @private
     * @returns void
     */
    private get orgInfo(): any
    {
        return this.$store.getters.orgInfo || {};
    }

     /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
     private get showRights(): any
    {
        return showRightsCode.includes(localStorage.getItem("appid"));
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
     private created(): void
    {
        // 获取机构信息
        this.getOrgInfo(localStorage.getItem("appid"));
    }

    /**
     * 获取机构详情
     * @private
     * @returns void
     */
     private async getOrgInfo(code: string): Promise<void>
    {
        try
        {
            let {content: result} =  await UserService.instance.getOrgInfo(code);

            if(result.data)
            {
                if (result.data.errorPage)
                {
                    this.$router.replace({name: "errPage", params: {orgName: result.data.orgName}})
                }
                else
                {
                    this.$store.dispatch("setOrgInfo", result.data);
                    if (!result.data.isSelf) // 团队
                    {
                        this.$router.replace({name: "login"})
                    }
                    else
                    {
                        this.getGeolocation();
                    }
                }
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /* 获取当前位置信息
     * @private
     * @returns void
     */
    private async getGeolocation(): Promise<void>
    {
        let appid = localStorage.getItem("appid");
        let employerConfig = globalConfig.employerConfig[appid];
        if (employerConfig && employerConfig.unLocation)
        {
            return;
        }
        this.$store.dispatch("setLocation", null);
        if (await tools.isWechat()) // 如果是微信环境
        {
            this.weixinLocation();
        }
        else
        {
            let data = await GaodeService.instance.getBaiduLocation();
            this.$store.dispatch("setLocation", data);
        }
    }

    /**
     * 登录
     * @private
     * @returns void
     */
    private async onregister(): Promise<void>
    {
        if (!this.modelData.phone || this.phoneErr)
        {
            return;
        }
        if(!this.isRead)
        {
            this.showOverlay = true
            // Toast("请先同意协议申明");
            return;
        }
        try
        {
            await UserService.instance.smsSend(this.modelData);
            Toast("验证码发送成功");
            this.$router.push({name: "verification", params: {phone: this.modelData.phone}})
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /* 获取当前位置信息 -- 微信
     * @private
     * @returns void
     */
    private async weixinLocation(): Promise<void>
    {
        let currentUrl = location.href;
        let {content: result} = await WechatService.instance.getWechatJSSDK(currentUrl);
        let loca = await WechatService.instance.getWechatLocation(result); // 获取经纬度-腾讯
        let locat = await GaodeService.instance.getCity((loca as any).longitude, (loca as any).latitude); // 通过经纬度获取城市-高德
        this.$store.dispatch("setLocation", locat);
    }

    /* 校验手机号
     * @private
     * @returns void
     */
    private onCheckPhone(): void
    {
        if (!/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.modelData.phone.replace(/\s*/g,"")))
        {
            this.phoneErr = true;
        }
        else
        {
            this.phoneErr = false;
        }
    }

    /* 跳转隐私协议
     * @private
     * @returns void
     */
     private onPrivacy(): void
    {
        this.showPrivacy = true;
    }

    /* 关闭隐私协议
     * @private
     * @returns void
     */
    private closePrivacy(): void
    {
       this.showPrivacy = false;
    }

    /* 跳转用户协议
     * @private
     * @returns void
     */
    private onAgreement(): void
    {
       this.showAgreement = true;
    }

    /* 关闭用户协议
     * @private
     * @returns void
     */
    private closeAgreement(): void
    {
        this.showAgreement = false;
    }

    /* 同意协议
     * @private
     * @returns void
     */
     private onAgree(): void
    {
        this.isRead = true;
       this.showOverlay = false;
    }
    
}
